<template>
  <div>
    <v-dialog v-model="dialogTutorial" max-width="1500px">
      <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
        <v-card-text style="background-color: #f1f4f9">
          <v-row>
            <!-- Left panel -->
            <v-col cols="12" md="3" class="no-padding ml-n2">
              <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                <v-card-title>
                  <v-img
                    :src="require('@/assets/images/logos/anavel_logo.png')"
                    max-height="150px"
                    max-width="120px"
                    alt="logo"
                    contain
                  ></v-img>
                </v-card-title>
                <v-card-title class="text-center justify-center muller-capitalized">
                  Mes Services Assurance
                </v-card-title>
                <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                <v-card-text class="no-padding">
                  <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px"> </v-img>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Stepper content -->
            <v-col cols="12" md="9" class="no-padding d-flex">
              <v-card class="flex-fill flex d-flex flex-column" flat>
                <v-stepper v-model="step" class="elevation-0">
                  <!-- Step 1 -->
                  <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1"> Introduction </v-stepper-step>
                    <v-divider></v-divider>
                    <!-- Step 2 -->
                    <v-stepper-step :complete="step > 2" step="2"> Agence </v-stepper-step>
                    <v-divider></v-divider>
                    <!-- Step 3 -->
                    <v-stepper-step :complete="step > 3" step="3"> Vos partenaires </v-stepper-step>

                    <v-stepper-step :complete="step > 4" step="4"> Vos produits </v-stepper-step>

                    <v-stepper-step :complete="step > 5" step="5"> Votre espace DUE </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items class="elevation-0 pa-5 h-100 h-full">
                    <v-card-text>
                      <!-- Step 1 Content with a blinking button and arrow -->
                      <v-stepper-content step="1" class="h-100 elevation-0 no-padding">
                        <v-card-title class="no-padding mt-5">Bienvenue sur Mes Services Assurance ! 😁</v-card-title>

                        <v-card-text class="no-padding">
                          Nous sommes ravis de vous accueillir sur notre plateforme. <br />
                          Vous venez de créer votre compte et de vous connecter pour la première fois. Nous vous
                          proposons un didacticiel rapide pour vous aider à améliorer votre expérience utilisateur sur
                          notre plateforme. <br />Vous pouvez passer cette étape en cliquant sur le bouton "Continuer"
                          en bas de la fenêtre.
                        </v-card-text>

                        <v-spacer> </v-spacer>
                        <v-card-actions class="text-right">
                          <v-spacer></v-spacer>
                          <v-btn
                            :class="{ 'tutorial-highlight': step === 1 }"
                            color="primary"
                            @click="goToAdministration"
                          >
                            Continuer
                            <v-icon right> fa-solid fa-arrow-right fa-beat </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-stepper-content>
                    </v-card-text>

                    <!-- Step 2 Content with another blinking button -->
                    <v-card-text>
                      <v-stepper-content step="2" class="h-100 elevation-0 no-padding">
                        <v-card-title class="no-padding"
                          >Votre espace de votre agence {{ $store.state.user.agency_name }}</v-card-title
                        >

                        <v-card-text class="no-padding mt-5">
                          Nous vous proposons maintenant de configurer votre espace d'administration. <br />
                          Dans cette catégorie, vous retrouverez :
                          <ul>
                            <li>
                              Gérer les paramètres de votre agence (Logo, mentions légales, localisation de vos
                              établissements, dirigeants et associés...)
                            </li>
                            <li>Gérer les utilisateurs</li>
                            <li>Gérer les services de votre organisation</li>
                            <li>Faire de la gestion des ressources humaines</li>
                            <ul>
                              <li>Gérer les collaborateurs de votre agence</li>
                              <li>Gérer les congés</li>
                              <li>Gérer les jours travaillés</li>
                              <li>Gérer les formations</li>
                            </ul>
                            <li>Créer vos prestataires externes</li>
                            <ul>
                              <li>Vos prestataires paies</li>
                              <li>Vos prestataires juridiques</li>
                            </ul>
                          </ul>
                        </v-card-text>

                        <v-spacer> </v-spacer>
                        <v-card-actions class="text-right">
                          <v-spacer></v-spacer>
                          <v-btn text @click="dialogTutorial = !dialogTutorial">
                            <v-icon left>mdi-eye</v-icon>
                            Visualiser</v-btn
                          >

                          <v-btn :class="{ 'tutorial-highlight': step === 2 }" color="primary" @click="goToPartners()">
                            Continuer
                            <v-icon right> fa-solid fa-arrow-right fa-beat </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-stepper-content>
                    </v-card-text>

                    <!-- Step 3 Content -->
                    <v-card-text>
                      <v-stepper-content step="3" class="h-100 elevation-0 no-padding">
                        <v-card-title class="no-padding"
                          >Votre espace partenaires de votre agence {{ $store.state.user.agency_name }}</v-card-title
                        >

                        <v-card-text class="no-padding mt-5">
                          Passons maintenant à la configuration de vos partenaires. <br />
                          Dans cette catégorie, vous retrouverez :
                          <ul>
                            <li>Le recensement de vos partenaires avec lesquelles vous travaillez</li>
                          </ul>
                        </v-card-text>

                        <v-spacer> </v-spacer>
                        <v-card-actions class="text-right">
                          <v-spacer></v-spacer>
                          <v-btn text @click="dialogTutorial = !dialogTutorial">
                            <v-icon left>mdi-eye</v-icon>
                            Visualiser</v-btn
                          >
                          <v-btn :class="{ 'tutorial-highlight': step === 3 }" color="primary" @click="goToProducts()">
                            Continuer
                            <v-icon right> fa-solid fa-arrow-right fa-beat </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-stepper-content>
                    </v-card-text>
                    <!-- Step 4 Content -->
                    <v-card-text>
                      <v-stepper-content step="4" class="h-100 elevation-0 no-padding">
                        <v-card-title class="no-padding"
                          >Votre espace produits de votre agence {{ $store.state.user.agency_name }}</v-card-title
                        >

                        <v-card-text class="no-padding mt-5">
                          Passons maintenant à la configuration des produits que vous travaillez. <br />
                          Dans cette catégorie, vous retrouverez :
                          <ul>
                            <li>Le paramètrage des produits</li>
                            <ul>
                              <li>La possibilité d'affecter un partenaire à un produit</li>
                              <li>La possibilité de paramètrer la fiche produit</li>
                              <li>La possibilité d'appliquer la typologie cliente cible du produit</li>
                            </ul>
                            <li>
                              La possibilité d'activer la fonction de parrainage produits
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" fab icon small>
                                    <v-icon small>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Le parrainage produit permet de réaliser un système de parrainage avec un client
                                  professionnel
                                </span>
                              </v-tooltip>
                            </li>
                          </ul>
                        </v-card-text>

                        <v-spacer> </v-spacer>
                        <v-card-actions class="text-right">
                          <v-spacer></v-spacer>
                          <v-btn text @click="dialogTutorial = !dialogTutorial">
                            <v-icon left>mdi-eye</v-icon>
                            Visualiser</v-btn
                          >
                          <v-btn :class="{ 'tutorial-highlight': step === 4 }" color="primary" @click="goToDUE()">
                            Continuer
                            <v-icon right> fa-solid fa-arrow-right fa-beat </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-stepper-content>
                    </v-card-text>

                    <!-- Step 5 Content -->
                    <v-card-text>
                      <v-stepper-content step="5" class="h-100 elevation-0 no-padding">
                        <v-card-title class="no-padding">Votre module de DUE</v-card-title>

                        <v-card-text class="no-padding mt-5">
                          Vous êtes maintenant sur le module de DUE. <br />
                          Vous pouvez par l'intermédiaire de ce module :
                          <ul>
                            <li>
                              Enregistrer un client grâce à son siret

                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" fab icon small>
                                    <v-icon small>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span
                                  >Le SIRET est un code INSEE unique qui identifie une entreprise en France composé de
                                  14 chiffres. <br />
                                  <span class="EoleYellow--text">
                                    Les informations sont récupérées automatiquement grâce à l'API de Pappers
                                  </span></span
                                >
                              </v-tooltip>
                            </li>
                            <li>Générer une décision unilatérale de l'employeur</li>
                          </ul>
                        </v-card-text>

                        <v-spacer> </v-spacer>
                        <v-card-actions class="text-right">
                          <v-spacer></v-spacer>
                          <v-btn text @click="dialogTutorial = !dialogTutorial">
                            <v-icon left>mdi-eye</v-icon>
                            Visualiser</v-btn
                          >
                          <v-btn
                            :class="{ 'tutorial-highlight': step === 5 }"
                            color="primary"
                            @click="completeTutorial"
                          >
                            Terminer le tutoriel
                          </v-btn>
                        </v-card-actions>
                      </v-stepper-content>
                    </v-card-text>
                  </v-stepper-items>
                </v-stepper>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-speed-dial v-if="!dialogTutorial" style="z-index: 9999" fixed bottom right>
      <template v-slot:activator>
        <v-btn v-model="fab" color="EoleYellow" dark fab large @click="openTutorial">
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else color="white">fa-solid fa-info fa-beat</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
  </div>
</template>

<script>
import config from "@/views/config/config"

export default {
  data: () => ({
    fab: false,
    dialogTutorial: true,
    step: 1,
    adminName: "",
    adminEmail: "",
  }),
  created() {
    this.tutorialStep = localStorage.getItem("tutorialStep")
    if (this.tutorialStep) {
      this.step = parseInt(this.tutorialStep)
    }
  },
  methods: {
    openTutorial() {
      this.dialogTutorial = true
    },
    completeTutorial() {
      this.dialogTutorial = false
      localStorage.setItem("tutorial", 0)
    },
    goToDUE() {
      this.$router.push("/services/due")
      this.step = 5

      localStorage.setItem("tutorialStep", this.step)
    },
    goToProducts() {
      this.$router.push("/products")
      this.step = 4

      localStorage.setItem("tutorialStep", this.step)
    },
    goToAdministration() {
      this.$router.push("/office")
      this.step = 2

      localStorage.setItem("tutorialStep", this.step)
    },
    goToPartners() {
      this.$router.push("/partners")
      this.step = 3

      localStorage.setItem("tutorialStep", this.step)
    },
  },
}
</script>
<style>
.tutorial-highlight {
  animation: tutorial-blink 1s infinite;
}

@keyframes tutorial-blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.tutorial-arrow {
  position: absolute;
  animation: bounce 2s infinite;
  font-size: 2rem; /* Taille de la flèche */
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.arrow-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -20px; /* Ajustez pour positionner la flèche au-dessus ou en-dessous de l'élément */
}
</style>
